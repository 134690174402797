
import BtnPrimary from '@/components/parts/btn/BtnPrimary.vue'
import DetailSVG from '@/components/svg/news/DetailSVG.vue'
import DetailLGSVG from '@/components/svg/news/DetailLGSVG.vue'
import PostSVG from '@/components/svg/news/PostSVG.vue'
export default {
  components: { DetailLGSVG, DetailSVG, BtnPrimary, PostSVG },
  props: {
    post: {
      type: Object,
      default: () => {},
    },
    delay: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tl: null,
    }
  },
  mounted() {
    this.tl = this.$gsap.timeline()
    this.tl.from(this.$el, { delay: this.delay * 0.1, translateX: 16, opacity: 0, duration: 0.1 })
    this.tl.from(this.$refs.heading, { opacity: 0, duration: 0.1 })
    this.tl.from(this.$refs.excerpt, { opacity: 0, duration: 0.1 })
    this.tl.from(this.$refs.btn.$el, { opacity: 0, translateY: 16, duration: 0.1 })
  },
}
