import { gql } from 'graphql-tag'

import { ImageFragment } from './fragments/parts'

export default gql`
  query Posts {
    posts(sort: "date:desc") {
      data {
        id
        attributes {
          title
          link
          excerpt
          date
          image {
            ...ImageFragment
          }
        }
      }
    }
  }
  ${ImageFragment}
`
