import { DateTime } from 'luxon'

export const parsePost = (p) => {
  return {
    id: p.id,
    title: p.attributes?.title,
    excerpt: p.attributes?.excerpt,
    date: DateTime.fromISO(p.attributes?.date).toFormat('dd MMM'),
    link: p.attributes?.link,
    image: p.attributes?.image,
  }
}
