
import NewsBlock from '@/components/parts/NewsBlock.vue'
import HeadingSM from '@/components/wrappers/HeadingSM.vue'
import VerticalLongSVG from '@/components/svg/detail/VerticalLongSVG.vue'
import Saw from '@/components/parts/Saw.vue'
import postsQuery from '@/apollo/queries/posts'
import { parsePost } from '@/utils/parse-post.js'
import Pagination from '@/components/parts/Pagination.vue'
import HorizontalSVG from '@/components/svg/detail/HorizontalSVG.vue'
import HorizontalShortSVG from '@/components/svg/detail/HorizontalShortSVG.vue'
export default {
  components: {
    HeadingSM,
    VerticalLongSVG,
    Saw,
    Pagination,
    NewsBlock,
    HorizontalSVG,
    HorizontalShortSVG,
  },
  props: {
    section: {
      type: Object,
      default: () => ({}),
    },
    sectionIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentIndex: 0,
      animating: false,
      posts: [],
      tl: null,
    }
  },

  async fetch() {
    this.animating = true
    const client = this.$apollo.getClient()

    const query = {
      query: postsQuery,
    }
    try {
      const apiData = await client.query(query)
      this.$nextTick(() => {
        this.parsePosts(apiData.data.posts.data)
        this.animating = false
      })
    } catch (e) {
      console.error(e)
    }
  },

  computed: {
    groupSize() {
      if (this.$screen['2xl']) {
        return 3
      } else if (this.$screen.lg) {
        return 2
      } else {
        return 1
      }
    },

    startEnd() {
      return {
        start: this.currentIndex * this.groupSize,
        end: this.groupSize + this.currentIndex * this.groupSize,
      }
    },
    slides() {
      return this.posts.slice(this.startEnd.start, this.startEnd.end)
    },
  },
  fetchOnServer: false,
  beforeMount() {
    this.$nuxt.$on('go', this.anim)
  },
  mounted() {
    this.tl = this.$gsap.timeline({
      scrollTrigger: {
        trigger: this.$el,
        // toggleActions: 'play reset play reverse',
        start: 'top bottom',
      },
    })
    this.tl.scrollTrigger.disable()

    const heading = new this.$SplitText(this.$refs.heading, { type: 'chars, words, lines' })
    this.tl.from(heading.chars, {
      opacity: 0,
      stagger: {
        each: 0.1,
        from: 'start',
        grid: 'auto',
        ease: 'power2.inOut',
      },
    })
    this.$refs.preheading && this.tl.from(this.$refs.preheading.$el, { opacity: 0, xPercent: -10, duration: 0.2 }, '<')
    this.$refs.body && this.tl.from(this.$refs.body, { opacity: 0, duration: 0.2 }, '>')

    const self = this
    this.$Draggable.create(this.$refs.drag, {
      type: 'x',
      // bounds: this.$el,
      bounds: { minX: -16, maxX: 16 },
      dragResistance: 0.75,
      edgeResistance: 1,
      inertia: true,
      zIndexBoost: false,
      snap: () => {
        return 0
      },
      onDrag() {
        if (this.x > 12) return self.prev()
        if (this.x < -12) return self.next()
      },
    })

    this.$emit('sectionMounted')
  },

  beforeDestroy() {
    this.$nuxt.$off('go', this.anim)
  },
  methods: {
    anim() {
      this.tl.scrollTrigger.enable()
    },
    parsePosts(rawPosts) {
      const posts = []
      rawPosts.forEach((p) => {
        posts.push(parsePost(p))
      })
      this.posts = posts
      this.$nextTick(() => {
        this.$ScrollTrigger.refresh()
        // this.$nuxt.$emit('refreshSaws')
      })
    },

    prev() {
      if (this.animating) return
      if (this.currentIndex !== 0) {
        this.animating = true
        this.$gsap
          .to(
            this.$refs.post.map((x) => x.$el),
            {
              opacity: 0,
              translateX: 16,
              duration: 0.1,
              delay: (index) => {
                return index * 0.1
              },
            }
          )
          .then(() => {
            this.currentIndex -= 1
            this.animating = false
          })
      }
    },
    next() {
      if (this.animating) return
      if (this.currentIndex + 1 < Math.ceil(this.posts.length / this.groupSize)) {
        this.animating = true
        this.$gsap
          .to(
            this.$refs.post.map((x) => x.$el),
            {
              opacity: 0,
              translateX: -16,
              duration: 0.1,
              delay: (index) => {
                return index * 0.1
              },
            }
          )
          .then(() => {
            this.currentIndex += 1
            this.animating = false
          })
      }
    },
  },
}
